video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: relative;
    right: 0;
    top: 0;
    z-index: -1;
}

#header_mobile {
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-image: url('../../images/header_Moment.jpg');
    position: relative;
}

.client-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    top: 0;
    left: 0;
}

.service-description {
    position: absolute;
    width: 100%;
    z-index: 7;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(0, 0, 0);
    color: rgb(209, 209, 209);
    text-align: center;
    opacity: 0%;
}

.service-description:hover {
    animation: fadeIn 1s;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}