body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 40;
  text-align: center;
}

/* .header-text {
  position: absolute;
  top: 50%;
  transform: translate(0%,-50%);
  width: 100%;
  text-align:center;
  z-index: 50;
  color: white;
  font-family: 'Raleway', 'sans-serif';
  font-weight: 300;
  font-size: clamp(1.9em, 5vw, 7em);
  letter-spacing: 5px;
  margin-bottom: 0px;
} */

.header-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align:center;
  z-index: 50;
  width: 99%;
  color: white;
  font-family: 'Raleway', 'sans-serif';
  font-weight: 300;
  font-size: clamp(40px, 4vw, 7em);
  letter-spacing: 5px;
  margin-bottom: 0px;
  word-break: keep-all;
  word-wrap: break-word;
}

.header-text.franchise-header {
  font-size: clamp(32px, 4vw, 7em)
}


.header-subtext {
  font-size: .5em;
  letter-spacing: 5px;
}

.header-text.franchise-header .header-subtext {
  margin-top: 15px;
  font-size: .5em;
}

a.cta {
  background-color: #0b56a4;
  color: white !important;
  padding: 4px 10px 2px 10px;
  border: 2px solid #0b56a4;
  text-decoration: none;
  font-family: 'Raleway', sans-serif;
  font-size: .85em;
  letter-spacing: .0705em;
  display: inline-block;
  /* font-weight: 500; */
}

a.cta:hover {
  background-color: white;
  color: #0b56a4 !important;
  border: 2px solid #0b56a4;
  transition: .5s;
}

button.cta {
  text-decoration: none !important;
  border: 2px solid #0b56a4 !important;
  color: #0b56a4 !important;
  background-color: transparent !important;
  font-size: .85em !important;
  font-family: 'Muli', sans-serif !important;
  letter-spacing: .07em !important;
  padding: 4px 10px 2px 10px;
  margin-left: 10px;
}

button.cta:disabled:hover {
  border: 2px solid #0b56a4 !important;
  color: #0b56a4 !important;
  background-color: transparent !important;
}

button.cta:hover {
  background-color: #0b56a4 !important;
  color: white !important;
  transition: .5s !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.644);
  /* background-color: rgba(0,0,0,.5); */
  z-index: 5;
}

.section-header {
  font-family: 'Raleway', sans-serif;
  color: #0b56a4;
  letter-spacing: .06em;
  line-height: 1.2em;
  margin-top: 1rem;
  margin-bottom: .9rem;
  font-size: clamp(25px, 2vw, 28px);
}

.section-subheader {
  font-family:'Raleway', sans-serif;
  font-size: .62em !important;
  letter-spacing: .1em;
}

.next-section {
    width: 75px;
    height: 60px;
}

.section-p {
  line-height: 1.6em;
  font-family: 'Roboto';
  font-weight: 300;
  letter-spacing: .04em;
  font-size: 15px;
}

.left-input,
.right-input {
    width: 48%;
}

.right-input {
    margin-left: 4%;
}

img.lg-screen-hide {
  display: block;
}

.charity-wrapper {
  padding-top: 50px;
  padding-bottom: 80px;
  margin-top: 80px;
}

.charity-wrapper .wrapper {
  width: 93%;
  max-width: 1400px;
  margin: 0 auto;
}

.charity-wrapper h1 {
  font-family: 'Raleway', sans-serif;
  color: #0b56a4;
  letter-spacing: .05em;
  margin-bottom: 50px;
  text-align: center;
}

@media only screen and (min-width: 410px) {
  a.cta {
    padding: 4px 20px 2px 20px;
  }
  button.cta {
    padding: 4px 20px 2px 20px;
  }
}

@media only screen and (min-width: 768px) {
  a.cta {
    padding: 4px 25px 2px 25px;
  }
  button.cta {
    padding: 4px 25px 2px 25px;
  }
}

@media only screen and (min-width: 992px) {
  .section-p {
    line-height: 1.4em;
  }
  .section-header {
    margin-top: 0rem !important;
  }
  /* .dees-container img {
    padding-left: 4rem;
  } */
  .left-text {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .right-text {
    padding-right: 3rem;
  }
  a.cta {
    padding: 3px 5px 2px 5px;
  }
  button.cta {
    padding: 4px 5px 2px 5px;
  }
}

@media only screen and (min-width: 1200px) {
  .section-p {
    line-height: 1.6em;
    font-size: 16px;
  }
  .section-header {
    margin-top: 1rem !important;
  }
  a.cta {
    padding: 4px 25px 2px 25px;
  }
  button.cta {
    padding: 4px 25px 2px 25px;
  }
  .header-text.franchise-header .header-subtext {
    font-size: .4em;
  }
}